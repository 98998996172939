/** @jsx h */

import type { VNode } from 'preact';

import { h } from 'preact';

import { isValidLocalPhone } from '../../../lib';
import { useConsentFormContext, useConsentPopupContext } from '../context';

import { ConsentPopupTextField } from './text-field';

type ConsentPopupPhoneFieldProps = {
    autofocus ?: boolean,
    placeholder ?: string,
    submitOnAutoComplete ?: boolean,
    className ?: string,
};

export const ConsentPopupPhoneField = ({
    autofocus,
    placeholder = 'Phone Number',
    submitOnAutoComplete = true,
    className
} : ConsentPopupPhoneFieldProps) : VNode => {
    const {
        setPhone,
        phone
    } = useConsentPopupContext();

    const {
        submit
    } = useConsentFormContext();

    return (
        <ConsentPopupTextField
            type={ 'tel' }
            id={ 'phone' }
            name={ 'phone' }
            autocomplete={ 'tel' }
            autofocus={ autofocus }
            placeholder={ placeholder }
            defaultValue={ phone }
            onValue={ setPhone }
            onAutoComplete={
                () => {
                    if (submitOnAutoComplete) {
                        void submit();
                    }
                }
            }
            inputPattern={ /^\d*$/ }
            required={ true }
            isValid={ isValidLocalPhone }
            deformatter={
                val => {
                    return val.replace(/\D+/g, '').replace(/^[01]/, '');
                }
            }
            maxLength={ 10 }
            className={ className }
        />
    );
};
