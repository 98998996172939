import type { Cancelable } from '../../types';

import { DOM_EVENT } from '../../constants';
import { debounce } from '../util';

import { listenForElementEvents } from './event';
import { isPhoneField, isValidLocalPhone } from './phone';

import type { PhoneNumber } from '@onetext/api';

export const isInputField = (element : HTMLElement) : element is HTMLInputElement => {
    if (element.tagName.toLowerCase() !== 'input') {
        return false;
    }

    return true;
};

type ListenForPhoneInputOptions = {
    selector : string,
    rootElement ?: HTMLElement,
    handler : (opts : {
        element : HTMLInputElement,
        phone : PhoneNumber,
    }) => void,
};

export const listenForPhoneInput = ({
    selector,
    rootElement = document.body,
    handler
} : ListenForPhoneInputOptions) : Cancelable => {
    const onPhoneInput = debounce((element : HTMLElement) => {
        if (isInputField(element) && isPhoneField(element) && isValidLocalPhone(element.value)) {
            handler({
                phone: element.value,
                element
            });
        }
    }, 500);

    return listenForElementEvents({
        selector,
        eventName: DOM_EVENT.KEYUP,
        filter:    element => {
            return isInputField(element) && isPhoneField(element);
        },
        rootElement,
        onElement: ({ element }) => onPhoneInput(element),
        handler:   ({ element }) => onPhoneInput(element)
    });
};
