/** @jsx h */
import type { ComponentChildren, VNode } from 'preact';

import { h } from 'preact';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { twMerge as tw } from 'tailwind-merge';

import { openKeyboard } from '../../../lib';
import { ConsentPopupPageContext, useConsentPopupContext } from '../context';

import { ConsentPopupForm } from './form';

import type { Milliseconds } from '@onetext/api';

let globalPageID = 0;

type ConsentPopupPageProps = {
    children : ComponentChildren,
    className ?: string,
    openKeyboardOnSubmit ?: boolean,
    submitDelay ?: Milliseconds,
    onSubmitStart ?: () => Promise<void> | void,
};

export const ConsentPopupPage = ({
    children,
    className = undefined,
    openKeyboardOnSubmit = false,
    submitDelay,
    onSubmitStart
} : ConsentPopupPageProps) : VNode | null => {
    const [ pageID ] = useState(() => {
        globalPageID += 1;
        return globalPageID;
    });

    const {
        activePageID,
        registerPage,
        submitPage
    } = useConsentPopupContext();

    useEffect(() => {
        return registerPage(pageID);
    }, [ pageID, registerPage ]);

    if (activePageID !== pageID) {
        return null;
    }

    const submit = useCallback(() => {
        if (openKeyboardOnSubmit) {
            openKeyboard();
        }

        return submitPage();
    }, [ submitPage, openKeyboardOnSubmit ]);

    const pageContext = useMemo(() => {
        return {
            submit
        };
    }, [ submitPage ]);

    return (
        <ConsentPopupPageContext.Provider value={ pageContext }>
            <ConsentPopupForm
                onSubmit={ submit }
                onSubmitStart={ onSubmitStart }
                submitDelay={ submitDelay }
            >
                <div className={ tw('relative w-full h-full pb-32 md:pb-16', className) }>
                    { children }
                </div>
            </ConsentPopupForm>
        </ConsentPopupPageContext.Provider>
    );
};
