/** @jsx h */

import type { FunctionComponent } from 'preact';

import { clsx } from 'clsx';
import { Fragment, h } from 'preact';

export enum SPINNER_ANIMATION {
    SPIN_TEASE = 'spin-tease',
    SPINNING = 'spinning',
    SPIN_FINAL = 'spin-final'
}

type SpinnerColors = {
    arrow : {
        background : string,
    },
    hubcap : {
        background : string,
        border : string,
    },
    wheel : {
        background : string,
        border : string,
    },
    freeGift : {
        background : string,
        text : string,
    },
    discount : {
        background : string,
        text : string,
    },
};

type ConsentPopupSpinnerProps = {
    animation ?: SPINNER_ANIMATION,
    colors ?: SpinnerColors,
    borderWidth ?: number,
};

const defaultColors : SpinnerColors = {
    arrow: {
        background: '#4E6A6C'
    },
    hubcap: {
        background: '#f2efe8',
        border:     '#ffffff'
    },
    wheel: {
        background: '#83bfb5',
        border:     '#83bfb5'
    },
    freeGift: {
        background: '#83bfb5',
        text:       '#ffffff'
    },
    discount: {
        background: '#ffffff',
        text:       '#000000'
    }
};

const DEFAULT_BORDER_WIDTH = 12;

const Arrow : FunctionComponent<{ backgroundColor : string }> = ({ backgroundColor }) => (
    <div
        className={
            clsx(
                'absolute',
                'w-[22px] h-[51px]',
                'top-[-12px] left-1/2',
                'z-[8]',
                'isolate',
                '-translate-x-[calc(50%+4px)] md:-translate-x-[calc(50%+6px)]'
            )
        }>
        <div
            data-popup-element={ 'arrow' }
            className={ 'w-[22px] h-[51px] z-[10] relative' }>
            <svg
                width={ '22' }
                height={ '51' }
                viewBox={ '0 0 22 51' }
                fill={ 'none' }
                xmlns={ 'http://www.w3.org/2000/svg' }>
                <path
                    d={
 `
     M0 20.1165
     C0 13 1.28333 -1.09271e-08 11 0
     C20.7167 1.09271e-08 22 13 22 20.1165
     C22 25.6147 17.7862 38.819 14.5747 48.0842
     C13.3691 51.5623 8.63089 51.5623 7.4253 48.0842
     C4.21378 38.819 0 25.6147 0 20.1165Z
 `
                    }
                    fill={ backgroundColor } />
            </svg>
        </div>
        <div
            data-popup-element={ 'arrow-shadow' }
            className={
                clsx(
                    'absolute rounded-full',
                    'w-[12px] h-[12px]',
                    'left-1/2 top-2',
                    'z-[6]',
                    '-translate-x-[calc(50%)]',
                    'shadow-[0px_12px_28px_4px_rgba(0,0,0,0.65)]'
                )
            }
        />
    </div>
);

const Hubcap : FunctionComponent<{
    backgroundColor : string,
    borderColor : string,
    borderWidth ?: number,
}> = ({
    backgroundColor,
    borderColor,
    borderWidth = DEFAULT_BORDER_WIDTH
}) => (
    <div
        className={
            clsx(
                'rounded-full w-[40%] h-[40%]',
                'absolute z-[4] top-1/2 left-1/2',
                `shadow-[inset_0px_0px_5px_2px_rgba(0,0,0,0.1)]`
            )
        }
        style={
            {
                backgroundColor,
                borderColor,
                borderWidth,
                transform: `translate(calc(-50% - ${ borderWidth / 2 }px), calc(-50% - ${ borderWidth / 2 }px))`
            }
        }
    />
);

export const ConsentPopupSpinner = ({
    animation = SPINNER_ANIMATION.SPIN_TEASE,
    colors = defaultColors,
    borderWidth = DEFAULT_BORDER_WIDTH
} : ConsentPopupSpinnerProps) : JSX.Element => {
    const spinnerColors = { ...defaultColors, ...colors };

    return (
        <div className={ 'relative w-[300px] h-[300px] md:w-[400px] md:h-[400px] isolate' }>
            <Arrow backgroundColor={ spinnerColors.arrow.background } />
            <Hubcap
                backgroundColor={ spinnerColors.hubcap.background }
                borderColor={ spinnerColors.hubcap.border }
                borderWidth={ borderWidth }
            />
            <div
                className={
                    clsx(
                        'w-[289.5px] h-[289.5px] md:w-[386px] md:h-[386px] rounded-full',
                        'flex flex-col items-center justify-center',
                        'relative z-[3] overflow-hidden',
                        'shadow-[0px_5px_20px_0px_rgba(51,51,51,0.16)]'
                    )
                }
                style={
                    {
                        backgroundColor: spinnerColors.wheel.background,
                        borderColor:     spinnerColors.wheel.border,
                        borderWidth
                    }
                }>
                <div
                    data-popup-element={ 'spinning-wheel' }
                    className={
                        clsx(
                            'w-full h-full',
                            'relative z-[2] overflow-hidden',
                            {
                                'spin-tease': animation === SPINNER_ANIMATION.SPIN_TEASE,
                                'spinning':   animation === SPINNER_ANIMATION.SPINNING,
                                'spin-final': animation === SPINNER_ANIMATION.SPIN_FINAL
                            }
                        )
                    }>
                    {
                        Array.from({ length: 8 }, (_, index) => {
                            const isFreeGift = index % 2 === 0;
                            const angle = (-15) + ((index / 8) * 360);
                            return (
                                <div
                                    key={ index }
                                    className={
                                        clsx(
                                            'absolute top-0 left-1/2',
                                            isFreeGift
                                                ? 'w-[54%]'
                                                : 'w-[31%]',
                                            'h-1/2',
                                            `z-[${ index + 1 }]`
                                        )
                                    }
                                    style={
                                        {
                                            backgroundColor: isFreeGift
                                                ? spinnerColors.freeGift.background
                                                : spinnerColors.discount.background,
                                            transform:       `translateX(calc(-50%)) rotate(${ angle }deg)`,
                                            transformOrigin: 'bottom center',
                                            clipPath:        'polygon(100% 0, 0 0, 50% 100%)'
                                        }
                                    }
                                >
                                    <div
                                        className={
                                            clsx(
                                                'flex flex-col items-center justify-start',
                                                'h-full w-full relative z-[1]',
                                                isFreeGift
                                                    ? 'md:pt-7 pt-5'
                                                    : 'md:pt-[26px] pt-5'
                                            )
                                        }>
                                        <div
                                            className={
                                                clsx(
                                                    'flex flex-col items-center justify-start',
                                                    'w-full relative',
                                                    'font-semibold uppercase text-center',
                                                    'twentieth-century',
                                                    'text-base',
                                                    'font-normal',
                                                    'tracking-[0.4px]',
                                                    'leading-[19.2px]',
                                                    'antialiased',
                                                    isFreeGift
                                                        ? clsx(
                                                            'md:text-[26px] md:h-[26px] md:leading-[26px]',
                                                            'text-[16px] h-[16px] leading-[16px]',
                                                            'z-[20]'
                                                        )
                                                        : clsx(
                                                            'md:text-[22px] md:h-[44px] md:leading-[22px]',
                                                            'text-[14px] h-[14px] leading-[14px]',
                                                            'z-[15]'
                                                        )
                                                )
                                            }
                                            style={
                                                {
                                                    color: isFreeGift
                                                        ? spinnerColors.freeGift.text
                                                        : spinnerColors.discount.text
                                                }
                                            }>
                                            {
                                                isFreeGift
                                                    ? (
                                                        <Fragment>
                                                            { 'Free' }<br />{ 'gift' }
                                                        </Fragment>
                                                    )
                                                    : (
                                                        <Fragment>
                                                            { '10%' }<br />{ 'off' }
                                                        </Fragment>
                                                    )
                                            }
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};
