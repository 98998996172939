import { addCustomerProperties, submitOptIn } from '../../api';
import { isValidLocalUSPhone, promiseTry, trackError } from '../../lib';
import { storeAccountLocally } from '../customer';

import type { JSONObject, PhoneNumber, TOKEN, TokenType } from '@onetext/api';

type SubmitOptInOptions = {
    accountToken ?: TokenType<TOKEN.ACCOUNT>,
    phone ?: PhoneNumber,
    customFlowParameters ?: JSONObject,
    customerProperties ?: Record<string, string>,
    email ?: string,
    allowRateLimitWelcomeFlow ?: boolean,
};

type CustomerOptInResponse = {
    accountToken : TokenType<TOKEN.ACCOUNT> | undefined,
};

export const submitCustomerOptIn = ({
    accountToken,
    phone: phoneCandidate,
    email,
    customerProperties,
    customFlowParameters,
    allowRateLimitWelcomeFlow = true
} : SubmitOptInOptions) : Promise<CustomerOptInResponse> => {
    const phone = phoneCandidate && isValidLocalUSPhone(phoneCandidate)
        ? phoneCandidate
        : undefined;

    if (phoneCandidate && !phone) {
        const err = new Error(`Invalid US phone number: ${ phoneCandidate }`);

        trackError(err, {
            type:      'optin_submit_invalid_us_phone',
            hardError: false
        });
    }

    const customerPropertiesPromise = promiseTry(() => {
        if (
            (accountToken || phone) &&
            customerProperties &&
            Object.keys(customerProperties).length
        ) {
            return addCustomerProperties({
                accountToken,
                phone,
                properties: customerProperties
            });
        }
    }).catch(err => {
        trackError(err, {
            type:      'optin_submit_customer_properties',
            hardError: false
        });
    });

    const submitOptInPromise = promiseTry(() => {
        if (phone || email) {
            return customerPropertiesPromise.then(() => {
                return submitOptIn({
                    accountToken,
                    phone,
                    email,
                    customFlowParameters,
                    requestTwoFactorCode: false,
                    allowWelcomeFlow:     true,
                    allowRateLimitWelcomeFlow
                });
            });
        }
    });

    return Promise.all([
        submitOptInPromise,
        customerPropertiesPromise
    ]).then(([
        optInResult
    ]) => {
        if (optInResult?.body.accountToken) {
            storeAccountLocally(optInResult.body.accountToken);

            return {
                accountToken: optInResult.body.accountToken
            };
        }

        return {
            accountToken
        };
    });
};
